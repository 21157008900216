const site = {
  // pathPrefix: '/gatsby-simple-blog',
  title: 'anyspace',
  author: 'Kou ShiXiang',
  description: '一个记录知识和生活的神秘小空间',  
  siteUrl: 'https://anyspace.cc',
  // twitter: 'kylemathews',
  // github: 'thundermiracle',
  // medium: 'thundermiracle',
  // facebook: 'thundermiracle',
  disqusShortName: 'anyspace',
  googleTrackingId: 'G-E7NM1ZBB2T',
  lang: 'zh-hans',
  displayTranslations: true,
  postsPerPage: 5,
};

const supportedLanguages = {
  en: 'English',
  'zh-hans': '简体中文',
};

module.exports = {
  site,
  supportedLanguages,
};
